.swap {
    padding: 20px;
}

.heading {
    text-align: center;
}
.heading h3 {
    margin-bottom: 10px;
}

.form {
    margin-block: 30px 10px;
}
.label > img {
    width: 32px;
}
.label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 20px;
}

.button {
    width: 50%;
}
