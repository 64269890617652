.tableContainer {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 35px 30px;
    overflow-y: hidden;
    overflow-x: auto;
    min-width: 600px;
}

.tableContainer h5 {
    color: var(--dark);
    font-weight: 600;
    margin-bottom: 30px;
}

.pagination {
    display: flex;
    justify-content: space-between;
}

.pagination .entries {
    color: var(--dark);
}

.pagination .paginationButtons {
    display: flex;
}

.pagination .paginationButtons .button {
    background-color: var(--main);
    /* border: 1px solid var(--border-color); */
    border-radius: var(--border-radius-standard);
    color: var(--dark);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    margin-right: 12px;
}

.tableLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid var(--dark);
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%, 0% 100%);
    animation: rotate 1.2s linear infinite;
}

.clampedText {
    white-space: nowrap;
    overflow: hidden;
}

.button:hover {
    background-color: var(--main-light) !important;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
