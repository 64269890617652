.pusd {
    background-color: var(--bg-dark);
    padding: 20px;
    margin: 0 auto;
    border-radius: var(--border-radius-standard);
    margin-top: 100px;
    color: var(--dark);
}

.inputTo {
    margin-top: 20px;
}
.balance {
    margin-block: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.balance .label {
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    color: var(--text-subtitle);
}

.balance .amount {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 20px;
    color: var(--dark);
}

.amount img {
    width: 35px;
}
.amountTotal {
    position: relative;
}
.coinIndetifier {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 15px;
    width: 35px;
}

.button {
    width: 100%;
    max-width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    padding: 15px;
    border-radius: var(--border-radius-medium);
    transition: all 0.5s ease;
}
.confirmPopup {
    padding: 20px;
}
.errorPopup {
    padding: 20px;
    text-align: center;
}
.errorPopup p {
    margin-bottom: 30px;
}
.bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .pusd {
        padding: 20px 20px;
    }
}

@media screen and (max-width: 576px) {
    .bottom {
        flex-direction: column;
    }
    .button {
        max-width: 100%;
    }
}
