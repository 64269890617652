.login {
    border-radius: 10px;
    padding: 40px 15px;
    text-align: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.15) 100%
        ),
        #000000;
}
.login img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
}

.login h5 {
    margin-bottom: 5px;
}
.login p {
    margin-bottom: 25px;
    margin-inline: auto;
    max-width: 500px;
}
.input {
    text-align: left;
    max-width: 400px;
    margin-inline: auto;
}

.inputUsername,
.inputPassword {
    margin: 0 2px;
}
.inputUsername input,
.inputPassword input {
    padding: 12px 20px;
    background-color: #343434;
    color: white;
    border-radius: 5px;
    caret-color: white;
}

.buttonLogin {
    max-width: 300px;
    margin-inline: auto;
    padding-inline: 20px;
    min-height: 46px;
    margin-top: 20px;
    color: white;
    font-weight: 500;
    font-size: 17px;
}
.buttonLogin:hover,
.inputUsername input:focus::placeholder,
.inputPassword input:focus::placeholder {
    color: white;
}
.forgot {
    margin-top: 10px;
}
.forgot a {
    transition: all 0.5s;
    text-align: 13px;
}
.forgot a:hover {
    color: white;
}
