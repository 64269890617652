.chartContainer {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 20px 23px;
    margin-bottom: 20px;
    width: 100%;
    overflow: auto;
}

.chartContainer h5 {
    color: var(--dark);
    font-weight: 600;
    margin-bottom: 30px;
}

.chartContainer p {
    color: var(--dark);
    font-weight: 600;
    margin-bottom: 30px;
}

.chartLabelsContainer {
    display: flex;
    gap: 25px;
}

.chartLabels {
    color: var(--dark);
}
.label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}
.label .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.totalData {
    display: flex;
    margin-left: 22px;
    gap: 20px;
    align-items: center;
}
.numbers {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 1px;
    color: var(--error-secondary) !important;
}
.numbersUp {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 1px;
    color: var(--success) !important;
}
.chart {
    max-width: 1440px;
}
.balance {
    font-size: 20px;
    font-weight: 500;
}
svg.up {
    color: var(--success);
    margin-right: 3px;
}
svg.down {
    color: var(--error);
    transform: rotate(180deg);
    margin-right: 3px;
}
