.login {
  border-radius: 10px;
  padding: 40px 15px;
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.15) 100%
    ),
    #000000;
}
.login img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.login h5 {
  margin-bottom: 20px;
}

.input {
  text-align: left;
  max-width: 400px;
  margin-inline: auto;
}

.inputUsername,
.inputPassword {
  margin-block: 0px 20px;
}
.inputUsername input,
.inputPassword input {
  padding: 12px 20px;
  background-color: #343434;
  color: white;
  border-radius: 5px;
  caret-color: white;
}

.buttonLogin {
  max-width: 300px;
  margin-inline: auto;
  padding-inline: 20px;
  min-height: 46px;
  margin-top: 20px;
  color: white;
  font-weight: 500;
  font-size: 17px;
}
.buttonLogin:hover,
.inputUsername input:focus::placeholder,
.inputPassword input:focus::placeholder {
  color: white;
}

.forgot {
  margin-top: 10px;
}
.forgot a {
  transition: all 0.5s;
  text-align: 12px;
}
.forgot a:hover {
  color: white;
}

.loginDisabled {
  backdrop-filter: blur(10px);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.15) 100%
    ),
    #000000;
  padding: 20px;
  text-align: center;
}

.button {
  max-width: 300px;
  margin-inline: auto;
  font-size: 14px;
  color: white;
  margin-top: 20px;
}
.button:hover {
  color: white;
}
.error {
  height: 20px;
  color: var(--error);
  text-align: center;
}
