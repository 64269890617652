.depositPopup {
    padding: 20px;
}
/* +Header */
.header {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.closeIcon {
    cursor: pointer;
}

.boxChoise {
    margin: 10px 0 10px 0;
}

/* + card */
.labelCard {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 0px 14px;
    border-radius: 10px;
    background-color: var(--bg-content);
    transition: all 0.3s ease;
    cursor: pointer;
    min-height: 70px;
    color: var(--dark);
    background-repeat: no-repeat;
    background-position: 104% -15px;
    background-size: 100px;
}
.labelCardSelected {
    background-color: var(--main);
}
.info {
    flex-grow: 2;
    margin-left: 20px;
}
.assetsName {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}
.labelChain {
    font-weight: 600;
    font-size: 14px;
}

.btnNext {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    padding: 10px;
    border-radius: var(--border-radius-medium);
    transition: all 0.5s ease;
}
.miniature {
    display: flex;
    align-items: center;
    position: relative;
}

.miniIcon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.fees {
    margin-top: 8px;
    line-height: 15px;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: var(--dark);
}

.inputAmount {
    margin-top: 30px;
    background-repeat: no-repeat;
    background-position: 104% -15px;
    background-size: 100px;
}
