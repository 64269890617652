.body {
	text-align: center;
	overflow-x: hidden;
}

.disclaimer {
	padding: 20px;
	color: var(--dark);
}

.header {
	text-align: center;
	margin-bottom: 20px;
}
.header h3 {
	margin-bottom: 10px;
}
.disclaimer h5 {
	margin-left: 20px;
	margin-block: 30px 20px;
}
.disclaimer p {
	margin-bottom: 20px;
}
.disclaimer .body {
	padding-inline: 10px;
	max-height: 320px;
	overflow-y: auto;
}
.button {
	margin-bottom: 10px;
	margin-top: 30px;
	width: 100%;
	max-width: 200px;
	margin-inline: auto;
}

.site {
	margin-block: 20px;
}
.tag {
	margin-block: 20px;
}

.backgroundImage {
	border-radius: var(--border-radius-min);
	margin-bottom: 10px;
	aspect-ratio: 16 / 9;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
