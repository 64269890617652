.layouting {
    display: flex;
    height: 100vh;
    overflow: hidden;
}
.mainContainer {
    width: 100%;
}
.contentContainer {
    max-width: 100%;
    background-color: var(--bg-content);
    height: calc(100% - var(--height-navbar));
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.7s;
    padding: 30px 28px;
}

@media screen and (max-width: 576px) {
    .contentContainer {
        padding: 30px 14px 150px;
    }
}
/*! da fare la query per il content padding  */
