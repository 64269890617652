.balance {
    grid-area: balance;
}
.currency {
    grid-area: currency;
}
.statistic {
    grid-area: statistic;
    width: 100%;
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
}
.activity {
    grid-area: activity;
}
.layout {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    grid-template-areas:
        "balance balance balance currency"
        "statistic statistic statistic currency"
        "statistic statistic statistic activity"
        "statistic statistic statistic activity";
}

@media screen and (max-width: 1399px) {
    .layout {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "balance balance currency currency"
            "statistic statistic activity activity";
    }
}
@media screen and (max-width: 1199px) {
    .layout {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "balance balance balance balance"
            "activity activity currency currency"
            "statistic statistic statistic statistic";
    }
}
@media screen and (max-width: 767px) {
    .layout {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            "balance balance balance balance"
            "activity activity activity activity"
            "currency currency currency currency"
            "statistic statistic statistic statistic";
    }
    .currency {
        margin-bottom: 20px;
    }
}
