.menu {
    margin-bottom: 20px;
}
.menu ul {
    display: flex;
    padding-bottom: 10px;
    column-gap: 15px;
    overflow-x: scroll;
    width: 100%;
}
.menu ul li {
    display: flex;
    align-items: center;
    column-gap: 9px;
    font-weight: 700;
    font-size: 16px;
    color: var(--text-subtitle);
    padding: 9px 14px;
    border-radius: var(--border-radius-standard);
    text-transform: capitalize;
    cursor: pointer;
    border-top: 2px solid transparent;
    transition: all 0.3s;
}
li.activeTab {
    background-color: var(--bg-dark);
    border-top: 2px solid var(--main) !important;
    /* color: var(--main) !important; */
}

.scroll {
    background-color: red;
    width: 100%;
}
