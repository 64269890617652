.loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.defaultLoaderImage {
    max-width: 200px;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
