.card {
    border-radius: var(--border-radius-medium);
    padding: 11px 15px;
    position: relative;
    z-index: 3;
}
.deep {
    position: relative;
}
.deep::before {
    background-color: var(--main);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -6px;
    z-index: 0;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/bg-shape-card.png");
}
.deep::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -3px;
    z-index: 2;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/bg-shape-card.png");
    background-size: cover;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 1px;
    background-color: var(--bg-dark);
}

.amount {
    font-size: 32px;
    font-weight: 600;
    color: var(--dark);
    text-align: right;
    position: relative;
    margin-top: 20px;
}
.amount div {
    position: absolute;
    bottom: -30px;
    right: 20px;
}
.coin {
    margin-block: 0px 26px;
    margin-inline: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.discount {
    color: var(--dark);
    font-weight: 700;
    font-size: 16px;
}
.coin img {
    width: 32px;
}

.plx {
    color: var(--main-light);
    font-weight: 700;
    font-size: 19px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.lineThrough {
    color: var(--dark);
}
.buttons {
    display: flex;
    gap: 30px;
}

.button {
    width: 50%;
    margin-inline: auto;
    padding-block: 8px;
    color: white;
    font-size: 16px;
    margin-bottom: 10px;
}

.packageName {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark);
    margin-bottom: 10px;
    padding-inline: 20px;
}
