.terms {
    background-color: var(--bg-dark);
    padding: 24px 20px;
    border-radius: var(--border-radius-standard);
    color: var(--dark);
}

.title {
    margin-bottom: 20px;
}
.date {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-subtitle);
}
.scrollable {
    max-height: calc(100vh - 330px);
    overflow-x: auto;
}

.scrollable p {
    margin-bottom: 15px;
}
.scrollable .underline {
    text-decoration: underline;
    margin-bottom: 20px;
}
.scrollable h5 {
    margin-left: 20px;
    margin-block: 25px 20px;
}
.scrollable ul {
    list-style-type: upper-roman;
    margin-left: 40px;
}
.scrollable ul.letter {
    list-style-type: lower-alpha;
    margin-left: 40px;
}
.scrollable ul.point {
    list-style-type: disc;
    margin-left: 40px;
}
.scrollable ul.number {
    list-style-type: decimal;
    margin-left: 40px;
}
.scrollable ul li {
    margin-bottom: 8px;
}
