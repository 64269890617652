.page {
    background-color: var(--dark);
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;
}
.center img {
    width: 100%;
    max-width: 750px;
}
.logo {
    margin-top: 20px;
    margin-left: 20px;
}
.text {
    color: white;
    font-size: 40px;
    font-weight: 700px;
    margin-top: 20px;
}
.text h4 {
    font-weight: 500;
    font-size: 22px;
    margin-top: 15px;
}
.text a {
    font-weight: 500;
    font-size: 22px;
    margin-top: 15px;
}

.pointRight {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    opacity: 0.8;
}
.pointLeft {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    opacity: 0.8;
}
.button {
    margin-top: 20px;
    color: white;
    padding: 12px;
    width: 100%;
    max-width: 500px;
    margin-inline: auto;
}
.logobig {
    position: absolute;
    top: -70px;
    left: -210px;
    transform: rotate(350deg);
    opacity: 0.1;
    width: 800px;
}

@media screen and (max-width: 767px) {
    .text h1 {
        font-size: 24px;
    }
    .text h4 {
        font-size: 14px;
    }
    .text a {
        font-size: 14px;
    }
    .pointRight,
    .pointLeft {
        width: 130px;
    }
}
