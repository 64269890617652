.advertiser {
    border-radius: var(--border-radius-medium);
    color: var(--dark);
    display: flex;
    background-image: url("../../../../Assets/images/staking-lock.png"),
        linear-gradient(180deg, #490b38 0%, #841865 100%);
    background-position: 95% 90%;
    background-repeat: no-repeat;
}

/* ?Commons */
.number {
    font-weight: 600;
    font-size: 28px;
}
.labelStaking {
    margin-left: 20px;
    font-weight: 600;
    font-size: 15px;
}

/*! Left */
.leftSide {
    width: 40%;
    padding: 20px 35px;
    border-right: 1px solid #c1c1c141;
}

.coin {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 40px;
}
.coin img {
    width: 32px;
}
/*! Right */
.rightSide {
    padding: 20px 35px 20px 0;
    width: 60%;
}
.free {
    border-bottom: 1px solid #c1c1c141;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lock {
    padding-top: 20px;
}
.buttons {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.button {
    background-color: transparent;
    border: 1px solid var(--dark);
    font-weight: 500;
    font-size: 14px;
    padding: 5px 25px;
}
.buttonsMobile {
    display: none;
}

@media screen and (max-width: 1199px) {
    .buttons {
        flex-direction: column;
        row-gap: 10px;
    }
    .button {
        width: 140px;
        padding: 5px;
    }
}

@media screen and (max-width: 767px) {
    .advertiser {
        flex-direction: column;
        text-align: center;
    }
    .labelStaking {
        margin-left: 0;
        /* text-align: center; */
    }

    .leftSide {
        width: 100%;
        border-right: none;
        padding-bottom: 0;
        /* text-align: center; */
    }
    .rightSide {
        width: 100%;
        /* text-align: center; */
        padding: 0 30px;
    }
    .free {
        border-bottom: none;
        justify-content: center;
        padding-block: 20px;
    }
    .lock {
        padding-top: 0;
        padding-bottom: 40px;
    }
    .buttons {
        display: none;
    }
    .buttonsMobile {
        display: block;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 567px) {
    .number {
        font-size: 24px;
    }
    .labelStaking {
        font-size: 14px;
    }
}
