/* Keyframes slide off left with opacity*/
@keyframes slideOffLeft {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.mainContainer {
    width: 100%;
    height: var(--height-navbar);
    background-color: var(--bg-dark);
    transition: all var(--transition-theme);
}
.mainContainer .content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}
.mainContainer .content .contentLeft {
    display: flex;
    align-items: center;
}

.content .menuButton {
    display: flex;
}
.content .menuButton svg {
    font-size: 40px;
    cursor: pointer;
    transform: scaleY(-1);
    color: var(--dark);
    transition: all var(--transition-theme);
}

.content .actions {
    display: flex;
    align-items: center;
    gap: 45px;
}
.content .actions .button {
    width: inherit;
    display: flex;
    font-style: normal;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    padding: 12px 20px;
    border: none;
    border-radius: 12px;
    background-color: var(--main);
    color: var(--dark-text);
}
.content .actions .button svg {
    margin-right: 12px;
    font-size: 20px;
}

.notificationsContainer {
    position: relative;
    cursor: pointer;
}

.content .actions .icon {
    position: relative;
    margin-top: 8px;
}
.content .actions .icon .notification {
    position: absolute;
    height: 16px;
    width: 16px;
    top: -1px;
    right: -1px;
    background-color: #fcfcfc;
    border-radius: 50%;
}
.content .actions .icon .notification:after {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ff6a55;
    border-radius: 50%;
}
.content .actions .icon svg {
    font-size: 30px;
    color: var(--secondary-text-color);
}

.content .actions .avatar {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: #f2f2f2;
    background-position: center;
    background-size: cover;
}
.content .actions .boxAvatar {
    cursor: pointer;
    border: 3px solid white;
    border-radius: 50%;
    transition: all 0.3s;
    position: relative;
}
.content .actions .boxAvatar:hover {
    border: 3px solid var(--main-light);
    border-radius: 50%;
}

.boxLanguage {
    background-color: var(--main-light);
    width: 34px;
    height: 34px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid white;
    background-position: center;
    background-size: cover;
}
.boxLanguage:hover {
    border: 2px solid var(--main-light);
    border-radius: 50%;
}

.profilePopupContainer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    padding-top: 74px;
    padding-right: 20px;
}
.profilePopupContainerLang {
    position: absolute;
    top: 0;
    right: 70px;
    z-index: 99;
    padding-top: 95px;
}

.profilePopup {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    min-width: 230px;
    overflow: hidden;
}

.popupLink {
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding: 10px 30px;
    transition: all var(--transition-theme) ease;
}

.popupLink:hover {
    background-color: var(--main);
}

.popupLink:hover.popupLink svg,
.popupLink:hover.popupLink h5 {
    color: var(--dark);
}

.popupLink svg {
    font-size: 24px;
    transition: all var(--transition-theme) ease;
    color: var(--secondary-text-color);
}

.popupLink h5 {
    font-size: 18px;
    font-weight: 500;
    transition: all var(--transition-theme) ease;
    color: var(--secondary-text-color);
    font-size: 14px;
    font-weight: 500;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--dark);
    opacity: 0.2;
}

.notificationsPopupContainer {
    position: absolute;
    top: 0;
    right: -50px;
    z-index: 999;
    padding-top: 72px;
    padding-right: 10px;
    max-height: 500px;
}

.notificationsPopup {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    min-width: 280px;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
}

.notificationMessage {
    padding: 10px 20px;
    position: relative;
    cursor: initial;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
}
.notificationOver {
    transition: all 0.3s ease;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: -55px;
    width: 55px;
    height: 100%;
    background-color: #87276cd8;
    z-index: 1;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
}

.notificationOver svg {
    font-size: 20px;
    color: var(--dark);
}

.notificationMessage:hover .notificationOver {
    right: 0;
}

.notificationMessage h5 {
    font-size: 14px;
    color: var(--dark);
    font-weight: 500;
    margin-bottom: 5px;
}

.notificationMessage p {
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-text-color);
}

.notificationLink {
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 30px;
    transition: all var(--transition-theme) ease;
    color: var(--secondary-text-color);
}

.notificationLink h5 {
    font-size: 14px;
    font-weight: 500;
}

.notificationLink:hover {
    background-color: var(--main);
}

.notificationLink:hover.notificationLink svg,
.notificationLink:hover.notificationLink h5 {
    color: var(--dark);
}

.notificationLink svg {
    font-size: 22px;
    transition: all var(--transition-theme) ease;
    color: var(--secondary-text-color);
}

.noNotifications {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.noNotifications p {
    font-size: 12px;
    font-weight: 400;
    color: var(--secondary-text-color);
}

.notificationsLoader {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.buttonLoader {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid white;
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%, 0% 100%);
    animation: rotate 1.2s linear infinite;
}

.langCircle {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    background-color: var(--main);
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1260px) {
    .content .searchbar {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .mainContainer .content {
        padding: 0 28px;
    }
}
@media screen and (max-width: 767px) {
    .mainContainer .content {
        padding: 0 14px;
    }
}

@media screen and (max-width: 420px) {
    .notificationsPopupContainer {
        position: absolute;
        top: 30px;
        right: -130px;
        z-index: 4;
        padding-top: 40px;
        padding-right: 20px;
        max-height: 500px;
    }
    .profilePopupContainer {
        position: absolute;
        top: 0;
        right: -30px;
        z-index: 4;
        padding-top: 70px;
        padding-right: 20px;
    }
}
