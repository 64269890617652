.completed {
    background-color: var(--bg-dark);
    padding: 20px 25px 30px 25px;
    max-width: 750px;
    margin: 0 auto;
    border-radius: var(--border-radius-standard);
    text-align: center;
    color: var(--dark);
}
.completed img {
    width: 100%;
}
.completed h5 {
    font-weight: 800px;
}
.completed h6 {
    font-weight: 600px;
    margin-top: 10px;
}
.cta {
    display: block;
    margin-top: 50px;
    font-weight: 600;
    color: var(--text-subtitle);
}
