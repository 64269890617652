.chartContainer {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 20px 23px;
    overflow: auto;
}

.header h5 {
    color: var(--dark);
    font-weight: 600;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
}

.header h6 {
    color: var(--text-subtitle);
    font-weight: 400;
    font-size: 14px;
    text-align: right;
}

.chart {
    margin: 0 auto;
    height: 494px;
}
