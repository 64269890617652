.title {
    color: var(--dark);
    margin-bottom: 30px;
}
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 27px;
    row-gap: 38px;
    margin-bottom: 40px;
}

@media screen and (max-width: 1399px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 676px) {
    .grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
