.confirmSwap {
    text-align: center;
    color: var(--dark);
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 30px;
    max-width: 600px;
    margin-inline: auto;
    margin-block: 70px;
}
.confirmSwap .title {
    font-weight: 400;
    margin-bottom: 25px;
}
.confirmSwap .title span {
    font-weight: 700;
}
.received {
    color: var(--text-subtitle);
    font-size: 14px;
}
.amount {
    font-weight: 400;
    font-size: 36px;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    color: var(--text-bright);
}
.amount img {
    width: 35px;
}
.processedInfo {
    margin-top: 30px;
    padding: 24px 0;
    border: 1px solid var(--text-subtitle);
    border-left: none;
    border-right: none;
}
.processedText {
    font-weight: 400;
    font-size: 15px;
    color: var(--text-bright);
}
.date {
    font-weight: 700;
    font-size: 22px;
    margin-top: 10px;
}
.buttons {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}
.buttons .button {
    width: 50%;
    text-align: center;
    padding: 20px 0;
    border: 1px solid var(--main);
}
.buttons .button:first-child {
    background-color: transparent;
    color: var(--main);
    border: 1px solid var(--main);
}
.buttons .button:first-child:hover {
    background-color: transparent;
    color: var(--dark);
    border: 1px solid var(--dark);
}
.successPopup,
.errorPopup {
    padding: 20px;
}
.buttonError {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .buttons {
        flex-direction: column;
        gap: 25px;
    }
    .buttons .button {
        width: 100%;
        padding: 10px 0;
    }
    .amount {
        font-size: 28px;
    }
}
