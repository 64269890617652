.header {
    color: var(--dark);
    text-align: center;
    margin-bottom: 55px;
}
.header h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 125%;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    margin-bottom: 5px;
}
.header h6 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.49em;
    text-transform: uppercase;
    color: #999999;
}

.cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    margin-bottom: 20px;
}

.card {
    display: flex;
    margin-inline: auto;
    column-gap: 20px;
}
.card h5 {
    color: var(--dark);
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.2px;
}

.card p {
    color: var(--text-subtitle);
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.4px;
    max-width: 350px;
}

.icon {
    margin-block: auto;
}
.boxImage {
    max-width: 1300px;
    margin-inline: auto;
    margin-top: 80px;
}

.boxImage img {
    width: 100%;
}

@media screen and (max-width: 1199px) {
    .cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 40px;
        row-gap: 30px;
        margin-bottom: 20px;
    }
}
