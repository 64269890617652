@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
    /* Read variables From dark to light */
    --transition-theme: 0.5s;
    --light-text: #1a1d1f;
    --gray-background: #efefef;
    --light-gray-background: #fcfcfc;
    --dark-text: white;
    --secondary-text-color: #6f767e;
    --light-shadow: #0000001a;
    --light: white;
    --light-sidebar-text-selected: #050b0f;
    --light-bg-sidebar-selected: #f8f8f8;
    --light-shadow-sidebar-selected: #00000017;
    --light-sidebar-bg: #ffffff;
    --light-bg-box: #f4f4f4;

    /************** *NEW* */
    --main: #87276c;
    --main-light: #cd6eb3;
    --dark: #000000;
    --light: #ffffff;

    --text-subtitle: #6f767e;
    --text-bright: #080808;

    --bg-content: #f8f8f8;
    --bg-dark: #eeeeee;

    --select-sidebar-element: #ffffff;

    --warning: #f5a200;
    --info: rgba(0, 89, 255, 0.76);
    --error: #f23936;
    --error-secondary: #d33c3c;
    --success: #20c9ac;
    --success-secondary: #40d034;

    --height-navbar: 90px;
    --width-sidebar: 400px;
    --width-sidebar-collapsed: 96px;
    --border-radius-min: 5px;
    --border-radius-standard: 10px;
    --border-radius-medium: 15px;
    /************** *NEW* */
}

[data-theme="dark"] {
    /************** *NEW* */

    --light: #000000;
    --dark: #ffffff;

    --text-subtitle: #9aa1a9;
    --text-bright: #f8f8f8;

    --bg-content: #343434;
    --bg-dark: #161616;
    /************** *NEW* */

    /* Input */
    --input-border-color: #5d5d5d;
    --input-background-placeholder-color: #5d5d5d;

    /* Social Buttons */
    --social-button-background: #1a1d1f;

    --light-shadow: #ffffff1a;
    --light-sidebar-text-selected: #ffffff;
    --light-shadow-sidebar-selected: #ffffff6b;
    --light-sidebar-bg: #161616;

    --light-text: #f8f8f8;
    --secondary-text-color: #9aa1a9;
    --light-bg-box: #232526;
    --light-gray-background: #black;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: var(--main);
}

/* --------------- SCROLLBAR */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background-clip: content-box;
    background-color: rgba(0, 0, 0, 0);
    width: 2px;
}

::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    background-color: var(--main);
    border-radius: 2px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

body {
    scrollbar-width: thin;
    scrollbar-color: var(--main) rgba(255, 255, 255, 0) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
}

input {
    font-family: "Poppins", sans-serif;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    /* Custom */
    width: 20px;
    height: 20px;
    border: 1px solid var(--text-subtitle);
    transition: 0.2s;
    border-radius: var(--border-radius-min);
    accent-color: var(--main);
}

/* When Checked */
input[type="checkbox"]:checked {
    background-color: var(--main);
    border-radius: var(--border-radius-min);
}
/* When checked content */
input[type="checkbox"]:checked:after {
    content: "\2714";
    color: white;
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    top: -1px;
    left: 3.5px;
}
/* Fix mobile color */
@media screen and (max-width: 576px) {
    input[type="checkbox"]:checked:after {
        font-size: 8px;
        filter: invert(100%) hue-rotate(18deg) brightness(1.7);
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../Assets/images/selectArrow.png");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 50%;
    background-size: 20px;
    border: none;
    border-radius: var(--border-radius-min);
}

a {
    text-decoration: none;
    color: var(--main);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin-bottom: 0;
}

h1 {
    font-size: 2.25rem; /* 36px */
    line-height: 110%;
}

h2 {
    font-size: 2rem; /* 32px */
    line-height: 110%;
}

h3 {
    font-size: 1.75rem; /* 28px */
    line-height: 125%;
}

h4 {
    font-size: 1.5rem; /* 24px */
    line-height: 130%;
}
h5 {
    font-size: 1.25rem; /* 20px */
    line-height: 135%;
}
h6 {
    font-size: 1.125rem; /* 18px */
    line-height: 135%;
}

p {
    font-size: 1rem;
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
}
