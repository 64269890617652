.layoutContainer {
    /* Attenzione al fill available mettere sempre prima */
    /* min-height: -webkit-fill-available; */
    height: 100vh;
    background-color: black;
    color: white;
    position: relative;
    overflow: hidden;
}

.outletContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 50px);
    overflow: auto;
    padding: 0 5px;
    width: 100%;
    max-width: 700px;
}

.pointRight {
    position: absolute;
    top: 35%;
    right: 0;
    transform: translate(0, -50%);
    opacity: 0.7;
}
.pointLeft {
    position: absolute;
    top: 70%;
    left: 0;
    transform: translate(0, -50%);
    opacity: 0.7;
}
