.card {
    border-radius: var(--border-radius-medium);
    /* background-color: var(--bg-dark); */
    padding: 11px 15px;
    position: relative;
    z-index: 3;
}
.limitedOne {
    position: relative;
}
.limitedOne::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -6px;
    z-index: 0;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/bg-shape-card.png");
}
.limitedOne::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -3px;
    z-index: 2;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/limitedOne.png"),
        linear-gradient(60deg, #ff8c21 13.4%, #ffe040 86.6%);
    background-size: cover;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 1px;
}
.limitedTwo {
    position: relative;
}
.limitedTwo::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -6px;
    z-index: 0;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/bg-shape-card.png");
}
.limitedTwo::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -3px;
    z-index: 2;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/limitedTwo.png"),
        linear-gradient(60deg, #b33deb 13.4%, #de8fff 86.6%);
    border-radius: 12px;
    background-size: cover;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 1px;
}
.limitedThree {
    position: relative;
}
.limitedThree::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -6px;
    z-index: 0;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/bg-shape-card.png");
}
.limitedThree::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: -3px;
    z-index: 2;
    border-radius: var(--border-radius-medium);
    background-image: url("../../../Assets/images/limitedThree.png"),
        linear-gradient(210deg, #d9a6ff 13.4%, #f68084 86.6%);
    border-radius: 12px;
    border-radius: 12px;
    background-size: cover;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 1px;
}

.amount {
    font-size: 32px;
    font-weight: 600;
    color: var(--dark);
    text-align: right;
    position: relative;
    margin-top: 20px;
}
.amount div {
    position: absolute;
    bottom: -30px;
    right: 20px;
}
.coin {
    margin-block: 0px 26px;
    margin-inline: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.discount {
    color: var(--dark);
    font-weight: 700;
    font-size: 16px;
}
.coin img {
    width: 32px;
}

.plx {
    color: var(--dark);
    font-weight: 700;
    font-size: 19px;
    display: flex;
    align-items: center;
}

.lineThrough {
    text-decoration: line-through;
    color: var(--dark);
}
.bottomBar {
    margin-inline: 20px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.bar {
    color: var(--dark);
    font-weight: 500;
    font-size: 14px;
    flex-grow: 2;
}
.progressBar {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: var(--border-radius-min);
    height: 7px;
    position: relative;
}
.progessActive {
    height: 100%;
    position: absolute;
    background: linear-gradient(to right, var(--dark) 13.4%, #ffffffd3 86.6%);
    border-radius: var(--border-radius-min);
}

.button {
    background-color: transparent;
    width: calc(100% - 140px);
    padding-block: 0;
    color: white;
    width: 100px;
    text-decoration: underline;
    text-align: right;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}
.button:hover {
    background-color: transparent;
    color: var(--main);
}
