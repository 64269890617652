.image {
    display: flex;
    align-items: center;
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 10px 20px;
    column-gap: 50px;
}
.image img.partners {
    width: 300px;
    border-radius: 5px;
}
.button {
    max-width: 300px;
    font-size: 16px;
    padding: 8px 8px;
}

@media screen and (max-width: 1250px) {
    .image {
        flex-direction: column;
    }
}
@media screen and (max-width: 350px) {
    .image img.partners {
        width: 100%;
    }
}
