.coinsAction {
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(3, 1fr);
	margin-bottom: 20px;
	color: var(--dark);
}
.coinCard {
	border-radius: var(--border-radius-standard);
	padding: 20px 23px;
	background-color: var(--bg-dark);
}
.card {
	border-radius: var(--border-radius-medium);
	padding: 15px 20px 30px;
	position: relative;
	z-index: 3;
	margin-bottom: 35px;
}
.deepOne {
	position: relative;
}
.deepOne::before {
	background-color: #ffce34;
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: 3px;
	left: 4px;
	z-index: 0;
	border-radius: var(--border-radius-medium);
}
.deepOne::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: -3px;
	z-index: 2;
	border-radius: var(--border-radius-medium);
	background-size: cover;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 1px;
	background-color: var(--bg-content);
}
.deepTwo {
	position: relative;
}
.deepTwo::before {
	background-color: #2e00af;
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: 3px;
	left: 4px;
	z-index: 0;
	border-radius: var(--border-radius-medium);
}
.deepTwo::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: -3px;
	z-index: 2;
	border-radius: var(--border-radius-medium);
	background-size: cover;
	opacity: 1;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 1px;
	background-color: var(--bg-content);
}
.deepThree {
	position: relative;
}
.deepThree::before {
	background-color: #7314ed;
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: 3px;
	left: 4px;
	z-index: 0;
	border-radius: var(--border-radius-medium);
}
.deepThree::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: -3px;
	z-index: 2;
	border-radius: var(--border-radius-medium);
	background-size: cover;
	opacity: 1;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 1px;
	background-color: var(--bg-content);
}
.deepFour {
	position: relative;
}
.deepFour::before {
	background-color: #53ae94;
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: 3px;
	left: 4px;
	z-index: 0;
	border-radius: var(--border-radius-medium);
}
.deepFour::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: -3px;
	z-index: 2;
	border-radius: var(--border-radius-medium);
	background-size: cover;
	opacity: 1;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 1px;
	background-color: var(--bg-content);
}
.information {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}
.coinBox {
	font-weight: 600;
	font-size: 20px;
	display: flex;
	align-items: center;
	gap: 10px;
}
.iconImage {
	width: 35px;
}
.lower {
	color: var(--error-secondary);
}
.growing {
	color: var(--success);
}
.chart {
	text-align: center;
}
.growNumber {
	display: flex;
	align-items: center;
	gap: 2px;
	font-weight: 600;
	font-size: 14px;
}
svg.up {
	color: var(--success);
	margin-right: 3px;
}
svg.down {
	color: var(--error-secondary);
	transform: rotate(180deg);
	margin-right: 3px;
}
.label {
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 3px;
}
.amount {
	font-weight: 600;
	font-size: 22px;
}
.amount span {
	font-weight: 600;
	font-size: 17px;
}

.button {
	background-color: var(--bg-content);
	border-radius: var(--border-radius-standard);
	margin-block: 10px;
	padding: 12px;
	font-weight: 500;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}
.button:hover {
	background-color: var(--main);
}
.button:first-child svg {
	font-size: 22px;
}

.coinCard:nth-child(3) .buttons .button:first-child,
.coinCard:nth-child(4) .buttons .button:first-child {
	height: 51px;
	background-color: var(--bg-dark);
	cursor: initial !important;
}

.blocked {
	margin-block: 15px 0;
	font-size: 14px;
	min-height: 25px;
}

.info {
	display: flex;
	align-items: center;
	gap: 10px;
}

.mark {
	height: 20px;
	width: 20px;
	background-color: rgb(128, 128, 128);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
}
.tooltip {
	position: absolute;
	top: -29px;
	left: 30px;
	background-color: var(--bg-dark);
	border-radius: var(--border-radius-standard);
	padding: 10px;
	font-size: 14px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	width: 220px;
	box-shadow: rgba(255, 255, 255, 0.15) 1.95px 1.95px 2.6px;
}

.mark:hover .tooltip {
	opacity: 1;
	visibility: visible;
}

@media screen and (max-width: 1399px) {
	.coinsAction {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media screen and (max-width: 1199px) {
	.coinsAction {
		grid-template-columns: repeat(1, 1fr);
	}
}
@media screen and (max-width: 767px) {
	.label {
		font-size: 16px;
	}
	.amount {
		font-size: 18px;
	}
}
