.stats {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
}
.valueBox {
    padding: 16px 20px;
    border-radius: var(--border-radius-standard);
}
.valueBox img {
    width: 35px;
}
.valueBox svg {
    font-size: 34px;
    color: white;
}
.valueBox:nth-child(1) {
    background: url("../../../../Assets/images/mini-point.png") no-repeat right,
        linear-gradient(60deg, #0538ff 13.4%, #6b57f5 86.6%);
    background-repeat: no-repeat;
}
.valueBox:nth-child(2) {
    background: url("../../../../Assets/images/mini-point.png") no-repeat right,
        linear-gradient(60deg, #c238cc 13.4%, #b554f2 86.6%);
}
.valueBox:nth-child(3) {
    background: url("../../../../Assets/images/mini-point.png") no-repeat right,
        linear-gradient(210deg, #3a65d3 13.4%, #75d473 86.6%);
}
.valueBox:nth-child(4) {
    background: url("../../../../Assets/images/mini-point.png") no-repeat right,
        linear-gradient(60deg, #ff8c21 13.4%, #ff6121 86.6%);
}

.title {
    margin-top: 8px;
    font-weight: 600;
    font-size: 16px;
    color: var(--dark);
}
.numbers {
    margin-top: 6px;
}
.numbers .number {
    font-weight: 600;
    font-size: 16px;
    color: var(--dark);
}
.numbers .number span {
    font-weight: 600;
    font-size: 24px;
    color: var(--dark);
}

.deposit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.depositBtn {
    font-size: 18px;
    max-height: 34px;
    padding-inline: 20px;
}
@media screen and (max-width: 1399px) {
    .stats {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
    }
}
@media screen and (max-width: 767px) {
    .stats {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media screen and (max-width: 576px) {
    .numbers {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
}

.pinco {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
