/*+ Input */
.inputFieldStyle {
    border: none;
    background-color: var(--bg-content);
    border-radius: var(--border-radius-standard);
}
.inputFieldStyle input {
    padding: 20px;
    padding-right: 60px;
    caret-color: var(--dark);
    color: var(--dark);
    border-radius: var(--border-radius-standard);
    transition: all 0.5s;
}
.inputFieldStyle input:focus {
    background-color: var(--main);
}
.inputFieldErrorStyle {
    border: none;
    background-color: var(--bg-content);
    border-radius: var(--border-radius-standard);
}
.inputFieldErrorStyle input {
    padding: 20px;
    padding-right: 60px;
    caret-color: var(--dark);
    color: var(--dark);
    border-radius: var(--border-radius-standard);
    transition: all 0.5s;
}
.inputFieldErrorStyle input:focus {
    background-color: var(--main);
}
.inputFieldSuccessStyle {
    border: none;
    background-color: var(--bg-content);
    border-radius: var(--border-radius-standard);
}
.inputFieldSuccessStyle input {
    padding: 20px;
    padding-right: 60px;
    caret-color: var(--dark);
    color: var(--dark);
    border-radius: var(--border-radius-standard);
    transition: all 0.5s;
}
.inputFieldSuccessStyle input:focus {
    background-color: var(--main);
}

.inputFieldLabelStyle {
    color: var(--text-subtitle);
    font-weight: 600;
    font-size: 16px;
}

/* + Buttons */
.buttonClass {
    background-color: var(--main);
    color: var(--dark);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonClass:hover {
    background-color: var(--main-light);
    color: var(--dark);
}
.buttonDisabledClass {
    background-color: black;
    opacity: 0.5;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}
.buttonDisabledClass:hover {
    background-color: black;
    color: white;
}

/* +Loader */
.buttonLoader {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid white;
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%, 0% 100%);
    animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
