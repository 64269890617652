.confirm {
    background-image: url("../../../../Assets/images/bg-confirm-buy.png");
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: var(--dark);
    text-align: center;
    position: relative;
}
.header {
    padding-top: 45px;
    color: var(--main);
}
.header h2 {
    font-weight: 800;
}
.header h6 {
    font-weight: 800;
    color: var(--dark);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    top: 110px;
    padding: 0 10px;
}
.button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 5px;
    width: 100%;
    max-width: 350px;
    font-size: 20px;
    font-weight: 400;
}
