.balance {
    padding: 24px;
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-medium);
    position: relative;
    z-index: 2;
}

.balance:before {
    content: "";
    background-image: url("../../../../Assets/images/icons/xpl-coin.png");
    background-repeat: no-repeat;
    background-position-x: -40px;
    background-position-y: 40px;
    background-size: 180px;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    opacity: 0.1;
}
.balance:after {
    content: "";
    background-image: url("../../../../Assets/images/point-right.png");
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: -40px;
    background-size: 180px;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    opacity: 0.5;
}
.titlePrincipal {
    color: var(--dark);
}
.grid {
    display: flex;
    align-items: center;
    gap: 15px;
}

.leftSide {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 2;
}

.myBalance .title,
.myBalance .amount,
.asset .title,
.asset .amount {
    font-weight: 500;
    font-size: 18px;
    color: var(--text-subtitle);
}
.myBalance .number,
.asset .number {
    font-weight: 600;
    font-size: 28px;
    color: var(--dark);
}
.separated {
    content: "";
    height: 120px;
    width: 1px;
    background: linear-gradient(
        0deg,
        #c1c1c1 1000%,
        #c1c1c1 5%,
        #c1c1c1 50%,
        #c1c1c1 5%
    );
}
.icon {
    background-color: rgba(39, 114, 240, 0.2);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons {
    width: 200px;
}

.button {
    border-radius: var(--border-radius-standard);
    font-weight: 500;
    font-size: 18px;
    padding-block: 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-content);
    margin-block: 20px;
}
.button:hover {
    background-color: var(--main);
}
.button svg {
    font-size: 20px;
}

@media screen and (max-width: 1600px) {
    .myBalance .title,
    .myBalance .amount,
    .asset .title,
    .asset .amount {
        font-weight: 500;
        font-size: 14px;
        color: var(--text-subtitle);
    }
    .myBalance .number,
    .asset .number {
        font-weight: 600;
        font-size: 24px;
        color: var(--dark);
    }
}
@media screen and (max-width: 1399px) {
    .grid {
        flex-direction: column;
    }
    .buttons {
        width: 100%;
    }
    .leftSide {
        flex-direction: column;
    }
    .separated {
        height: 1px;
        width: 50%;
        margin-block: 10px;
    }
    .balance:after {
        background-position-x: 100%;
        background-position-y: -40px;
    }
    .titlePrincipal {
        margin-bottom: 20px;
    }
    .balance {
        padding-bottom: 4px;
    }
}
@media screen and (max-width: 768px) {
    .grid {
        flex-direction: column;
    }
    .leftSide {
        flex-direction: column;
        align-items: flex-start;
    }
    .separated {
        display: none;
    }
    .buttons {
        width: 100%;
    }
}
