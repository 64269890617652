.basicInfo {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 35px 50px;
    color: var(--dark);
}
.title {
    font-weight: 600;
    margin-bottom: 60px;
}
.row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.label {
    color: var(--text-subtitle);
    font-weight: 600;
    font-size: 16px;
}
.boxData,
.boxDataSelect {
    width: 100%;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    margin-inline: 7px;
    background-color: var(--bg-content);
    border-radius: var(--border-radius-min);
}

.boxDataSelect{
    padding: 0;
    margin-inline: 0;
}

.select {
	background-color: var(--light-bg);
	outline: none;
	border: 1px solid var(--layout-bg);
	position: relative;
	border-radius: var(--br-5);
}
.selected {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.selected > span {
	display: flex;
	align-items: center;
}
.actual {
	column-gap: 10px;
	display: flex;
	align-items: center;
}
.actual img {
	width: 22px;
}
.selected .arrow svg {
	margin-right: 0;
	transition: all var(--transition-theme) ease;
}
.selected .arrowOpened svg {
	transform: rotate(180deg);
	margin-right: 0;
	transition: all var(--transition-theme) ease;
}
.options {
	border: 1px solid var(--bg-content);
	border-top: none;
	background-color: var(--bg-content);
	position: absolute;
	width: 100%;
	left: 0;
	top: 46px;
	transition: all 0.3s;
	overflow: hidden;
	visibility: hidden;
	z-index: 2;
	opacity: 0;
}
.optionsOpened {
	border: 1px solid var(--bg-content);
	background-color: var(--bg-dark);
	position: absolute;
	border-top: none;
	width: 100%;
	left: 0;
	top: 46px;
	transition: all 0.3s;
	overflow-y: auto;
	visibility: visible;
	z-index: 2;
	opacity: 1;
	border-radius: 0 0 5px 5px;
}

.options > div,
.optionsOpened > div,
.select {
	padding: 12px 22px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.optionsOpened > div:hover {
	background-color: var(--bg-content);
}
.options > div svg,
.optionsOpened > div svg,
.select svg {
	font-size: 20px;
	margin-right: 10px;
}


.inputProfile input{
    padding: 10px 20px;
    width: 100%;
}

.button{
    max-width: 200px;
    margin-top: 20px;
    margin-inline: auto 0;
}

.confirm,
.success{
    padding: 20px;
    text-align: center;
}
.confirm p,
.success p{
    margin-block: 20px;
    font-size: 14px;
}

.form {
    margin-bottom: 34px;
}
.form div:first-child {
    display: flex;
    column-gap: 10px;
    justify-content: center;
}

.form input {
    width: 60px;
    height: 60px;
    outline: none;
    border-radius: 10px;
    text-align: center;
    border: 1px solid var(--bg-content);
    font-size: 25px;
    background-color: var(--bg-content);
    color: white;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.errorCall{
    margin-top: 20px;
}

@media screen and (max-width: 767px) {
    .basicInfo {
        padding: 35px 25px;
        color: var(--dark);
    }
    .row {
        align-items: unset;
        flex-direction: column;
    }
    .boxData {
        margin-inline: 0px;
    }
    .label {
        margin-bottom: 5px;
    }
    .userInfo .row:first-child .boxData:nth-child(2) {
        border-radius: 5px 5px 0 0;
    }
    .userInfo .row:first-child .boxData:nth-child(3) {
        border-radius: 0 0 5px 5px;
    }
}

@media screen and (max-width: 455px) {
    .form input {
        width: 47px;
        height: 47px;
    }
}
@media screen and (max-width: 380px) {
    .form input {
        width: 39px;
        height: 39px;
    }
}
