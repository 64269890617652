.seedBox {
    color: var(--dark);
    border-radius: var(--border-radius-standard);
    display: flex;
    align-items: center;
    position: relative;
    column-gap: 30px;
}

.infoSeed {
    display: flex;
    column-gap: 15px;
    color: var(--text-bright);
}
.infoSeed img {
    width: 52px;
}
.infoText {
    margin-top: 8px;
}
.textLabel {
    display: flex;
    align-items: center;
    gap: 20px;
}
.titleLabel {
    font-weight: 600;
    font-size: 12px;
    color: var(--text-subtitle);
    margin-bottom: 5px;
}
.title {
    font-weight: 800;
}
.subTitle {
    margin-top: 10px;
    font-size: 22px;
    font-weight: 500;
}

.discount {
    color: var(--warning);
    font-weight: 800;
}

.limited {
    text-align: right;
    position: absolute;
    bottom: 53px;
    right: 35px;
    font-weight: 600;
    font-size: 16px;
}

.limitBar {
    margin-top: 8px;
    background-color: #343434;
    width: 340px;
    height: 7px;
    border-radius: 5px;
    position: relative;
}
.bought {
    position: absolute;
    height: 100%;
    background: linear-gradient(to right, #790ee4 0.07%, #b8238e 107.81%);
    border-radius: 5px;
}

@media screen and (max-width: 1440px) {
    .seedBox {
        justify-content: space-evenly;
    }
    .text {
        display: flex;
        flex-direction: column;
    }
    .limited {
        position: unset;
        text-align: left;
        margin-top: 40px;
    }
    .textLabel {
        justify-content: center;
        gap: 20px;
    }
}
@media screen and (max-width: 1250px) {
    .seedBox {
        column-gap: 30px;
        padding: 30px 20px;
    }
}

@media screen and (max-width: 767px) {
    .seedBox {
        padding: 30px 40px;
        flex-direction: column;
        row-gap: 40px;
        align-items: center;
    }
    .limited {
        position: unset;
        text-align: center;
    }
    .infoSeed {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 0;
    }
    .titleLabel {
        position: unset;
    }
    .discount {
        margin-bottom: 0;
    }
    .limited,
    .limitBar {
        width: 100%;
    }
}
