.header {
    color: var(--dark);
    margin-bottom: 20px;
}

.title {
    margin-bottom: 6px;
    font-weight: 800;
}
.subtitle {
    font-weight: 500;
}
