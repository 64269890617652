.body {
	text-align: center;
	overflow-x: hidden;
}

.disclaimer {
	padding: 20px;
	color: var(--dark);
}

.header {
	text-align: center;
	margin-bottom: 10px;
}
.header h3 {
	margin-bottom: 30px;
}
.disclaimer h5 {
	margin-left: 20px;
	margin-block: 30px 20px;
}
.disclaimer p {
	margin-bottom: 20px;
}
.disclaimer .body {
	padding-inline: 10px;
}
.button,
.buttonClose {
	margin-bottom: 10px;
	margin-top: 30px;
	margin-inline: auto;
	/* font-size: 16px; */
	min-width: 80px;
}

.button{
	flex-grow: 2;
}

.site {
	margin-block: 20px;
}
.tag {
	margin-block: 20px;
}

.buttons{
	display: flex;
	align-items: center;
	gap: 20px;
}