.timer {
    padding: 20px;
    text-align: center;
}
.timer p {
    max-width: 280px;
    margin: 20px auto 30px;
}

.button {
    max-width: 280px;
    margin-inline: auto;
}
