.feedback {
    padding: 20px;
    text-align: center;
}
.feedback img {
    max-width: 250px;
}
.message {
    margin-block: 20px 30px;
    font-size: 18px;
    font-size: 500;
}
