.banner {
    background-color: var(--bg-dark);
    margin-bottom: 60px;
    border-radius: var(--border-radius-standard);
    height: 320px;
    overflow: hidden;
    color: var(--dark);
}

.backgroundBanner {
    height: 173px;
    background-color: black;
    background-image: url("../../../../Assets/images/banner-profile.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: relative;
}

.avatar {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid white;
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
    background-color: var(--bg-dark);
}

.info {
    height: calc(320px - 185px);
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.name {
    font-weight: 700;
    font-size: 18px;
}

.username {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-subtitle);
}
