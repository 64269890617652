.transaction {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}
.transaction .icon {
    width: 25px;
    height: 25px;
}
.transaction svg {
    width: 25px;
    height: 25px;
    background-color: var(--bg-content);
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pending {
    background-color: var(--warning);
    display: inline-block;
    width: 85px;
    text-align: center;
    border-radius: var(--border-radius-min);
    padding-block: 4px;
}
.partial {
    background-color: var(--info);
    display: inline-block;
    width: 85px;
    text-align: center;
    border-radius: var(--border-radius-min);
    padding-block: 4px;
}
.completed {
    background-color: var(--success-secondary);
    display: inline-block;
    width: 85px;
    text-align: center;
    border-radius: var(--border-radius-min);
    padding-block: 4px;
}
.cancelled {
    background-color: var(--error-secondary);
    display: inline-block;
    width: 85px;
    text-align: center;
    border-radius: var(--border-radius-min);
    padding-block: 4px;
}
.expired {
    background-color: black;
    display: inline-block;
    width: 85px;
    text-align: center;
    border-radius: var(--border-radius-min);
    padding-block: 4px;
}
