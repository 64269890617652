.size {
  width: 100%;
}
/* keyframes Floating up and down with Filter drop-shadow */

@keyframes floating {
  0% {
    transform: translateY(0px);
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
  }
  50% {
    transform: translateY(-10px);
    filter: drop-shadow(0px 30px 10px rgba(0, 0, 0, 1));
  }
  100% {
    transform: translateY(0px);
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
  }
}

.loaderContainer {
  position: relative;
  width: 100px;
  height: 100px;
  margin-inline: auto;
}

.loaderContainer img {
    width: 100px;
  position: absolute;
  animation: floating 2s ease-in-out infinite;
}
