.advertiser {
    border-radius: var(--border-radius-medium);
    background: linear-gradient(180deg, #490b38 0%, #841865 100%);
    padding: 20px 35px;
    color: var(--dark);
}
.coin {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 40px;
}
.coin img {
    width: 32px;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    max-width: 350px;
    font-weight: 400;
    font-size: 14px;
}
.button {
    background-color: transparent;
    border: 1px solid var(--dark);
    font-weight: 500;
    font-size: 16px;
    padding: 5px 42px;
}

.progress {
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item {
    text-align: center;
    color: var(--text-subtitle);
}

@media screen and (max-width: 567px) {
    .info {
        flex-direction: column;
        row-gap: 30px;
    }
    .label {
        text-align: center;
    }
}
