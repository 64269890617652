.confirmRedeem {
    padding: 20px;
}

.header {
    text-align: center;
    margin-bottom: 30px;
}
.header > h3 {
    margin-bottom: 5px;
}
.header > h4 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header > h4 > img {
    width: 32px;
    margin-right: 10px;
}
.header .alert {
    margin-block: 30px;
    max-width: 330px;
    margin-inline: auto;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 20px;
}

.button {
    width: 50%;
}
