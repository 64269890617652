.packages {
    color: var(--dark);
}
.title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 20px;
}

.cardStak {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 30px;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
}

.cardStak:before {
    content: "";
    background-image: url("../../../../Assets/images/icons/xpl-coin.png");
    background-repeat: no-repeat;
    background-position-x: 102.5%;
    background-position-y: -35px;
    background-size: 180px;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    opacity: 0.1;
}
.quantity {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttons {
    display: flex;
    gap: 20px;
}
.buttonSwap,
.buttonRedeem {
    background-color: transparent;
    border: 1px solid var(--dark);
    font-weight: 500;
    font-size: 14px;
    padding: 5px 25px;
}
.buttonSwap svg,
.buttonRedeem svg {
    font-size: 16px;
    margin-right: 8px;
}
.buttonRedeem svg {
    font-size: 14px;
    margin-right: 8px;
}
.fee {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 50px;
    text-align: center;
}
.free {
    color: var(--success-secondary);
}
.locked {
    color: var(--error-secondary);
}
.date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 14px;
    opacity: 0.8;
}
.end {
    text-align: right;
}

.step {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    gap: 4px;
    margin-bottom: 14px;
}
.notPassed {
    background-color: var(--bg-content);
    height: 5px;
    border-radius: var(--border-radius-min);
}
.passed {
    background-color: var(--success-secondary);
    height: 5px;
    border-radius: var(--border-radius-min);
}
.percenteage {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    opacity: 0.8;
}

.progress {
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item {
    text-align: center;
    color: var(--text-subtitle);
}

@media screen and (max-width: 767px) {
    .fee {
        flex-direction: column;
        row-gap: 20px;
    }
    .quantity {
        flex-direction: column;
        row-gap: 20px;
    }
}
