.sidebar {
    height: 100%;
    width: var(--width-sidebar);
    box-shadow: 2px 0px 9px var(--light-shadow);
    background-color: var(--bg-dark);
    transition: all var(--transition-theme);
}
.sidebarCollapse {
    height: 100%;
    width: var(--width-sidebar-collapsed);
    box-shadow: 2px 0px 9px var(--light-shadow);
    background-color: var(--bg-dark);
    transition: all var(--transition-theme);
}
.boxLogo {
    height: var(--height-navbar);
    display: flex;
    align-items: center;
    padding: 0 24px;
    overflow: hidden;
}
.boxLogo img {
    max-height: 25px;
    transition: all 0.5s;
}
.sidebarCollapse .boxLogo img {
    max-height: 25px;
    transition: all 0.5s;
    margin-left: 5px;
}

.boxNav {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--height-navbar));
}

.boxNav .navigator {
    flex-grow: 2;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100px;
    padding-bottom: 100px;
}
.boxNav .navigator nav {
    padding: 40px 24px 24px;
}
.navigator ul li a {
    display: flex;
    align-items: center;
    width: 100%;
}
.navigator ul .element {
    cursor: pointer;
}

.navigator ul .element div.mainCategory {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 12px 14px;
    border-radius: 12px;
    margin-bottom: 12px;
    transition: all 0.5s;
}
.navigator ul .element div.mainCategoryOpenCollapsed {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 12px 14px;
    border-radius: 12px;
    margin-bottom: 12px;
}
.mainCategory .innerCategory {
    display: flex;
    align-items: center;
}
.mainCategoryOpenCollapsed .innerCategory {
    display: flex;
    align-items: center;
}

.navigator ul li svg.icon {
    height: 21px;
    width: 20px;
    color: var(--text-subtitle);
}
.navigator ul li svg.arrow {
    color: var(--text-subtitle);
    font-size: 24px;
    cursor: pointer;
    transition: all var(--transition-theme);
}

.sidebarCollapse .navigator ul li svg.arrow {
    display: none;
}
.navigator ul li svg.arrowActive {
    color: var(--text-subtitle);
    font-size: 24px;
    cursor: pointer;
    transform: rotate(180deg);
    transition: all var(--transition-theme);
}
.navigator ul li svg path {
    /* fill: var(--text-subtitle); */
    transition: all 0.5s;
}

.navigator ul li.elementActive {
    position: sticky;
    top: 0;
    z-index: 5;
    cursor: pointer;
    background-color: var(--bg-dark);
}
.navigator ul li.elementSticky {
    position: sticky;
    top: 0;
    z-index: 5;
    cursor: pointer;
    background: var(--light-sidebar-bg);
}

.navigator ul .elementActive div.mainCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 14px;
    border-radius: 12px;
    margin-bottom: 12px;
    background: var(--main);
    color: var(--light);
    box-shadow: inset 1px 2px 4px var(--light-shadow-sidebar-selected);
    transition: all var(--transition-theme);
}
.navigator ul .elementSticky div.mainCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 14px;
    border-radius: 12px;
    margin-bottom: 12px;
    background: var(--light-sidebar-bg);
    color: var(--light);
    transition: all var(--transition-theme);
}

.navigator ul .elementActive div.mainCategoryOpenCollapsed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 14px;
    border-radius: 12px;
    margin-bottom: 12px;
    background: transparent;
    color: var(--light);
    box-shadow: inset 1px 2px 4px transparent;
}

.navigator ul .elementActive .categoryText,
.navigator ul .elementActive .arrow {
    transition: all 0.5s;
    color: var(--select-sidebar-element);
}
.navigator ul .element .mainCategory:hover .categoryText,
.navigator ul .element .mainCategory:hover .arrow {
    transition: all 0.5s;
    color: var(--dark);
}
.navigator ul .elementActive svg path {
    color: var(--select-sidebar-element);
}
.navigator ul .element .mainCategory:hover svg {
    fill: var(--dark);
}
.navigator ul .mainCategoryOpenCollapsed .categoryText,
.navigator ul .mainCategoryOpenCollapsed .arrow {
    transition: all 0.5s;
    color: var(--text-subtitle);
}
.navigator ul .elementActive .mainCategoryOpenCollapsed svg path {
    transition: all 0.5s;
    color: var(--text-subtitle);
}

.element .mainCategoryOpenCollapsed:hover span,
.element .mainCategoryOpenCollapsed:hover svg,
.elementActive .mainCategoryOpenCollapsed:hover span,
.elementActive .mainCategoryOpenCollapsed:hover svg {
    transition: all 0.5s;
    color: var(--dark);
}
.navigator ul .element .mainCategoryOpenCollapsed:hover svg path,
.navigator ul .elementActive .mainCategoryOpenCollapsed:hover svg path {
    color: var(--dark);
}
.navigator ul li .categoryText {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    transition: all 0.8s;
    color: var(--text-subtitle);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}
.sidebarCollapse .navigator ul li .categoryText {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: var(--text-subtitle);
    transition: all 0.3s;
    opacity: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.navigator div.subCategory {
    margin-left: 22px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--text-subtitle);
}
.navigator div.subCategory::before {
    content: "";
    border-bottom: 3px solid var(--gray-background);
    border-left: 3px solid var(--gray-background);
    border-bottom-left-radius: 10px;
    height: 11px;
    width: 11px;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 0;
}
.navigator ul li:last-child div.subCategory::before {
    content: "";
    border-bottom: 3px solid var(--gray-background);
    border-left: 3px solid var(--gray-background);
    border-bottom-left-radius: 10px;
    height: 11px;
    width: 11px;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 0px;
}

.navigator ul li div.subCategory::after {
    content: "";
    height: 100%;
    width: 3px;
    background-color: var(--gray-background);
    position: absolute;
    top: 0;
    left: 0;
}
.navigator ul li:last-child div.subCategory::after {
    content: "";
    height: 12px;
    width: 3px;
    background-color: var(--gray-background);
    position: absolute;
    top: 0;
    left: 0;
}
.navigator div.subCategory .innerSub {
    margin-left: 13px;
}
.navigator div.subCategory .innerSub div.innerElement {
    padding: 12px 12px 16px;
    border-radius: 12px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--text-subtitle);
    transition: var(--transition-theme);
}
.navigator div.subCategory:hover div.innerElement {
    color: var(--dark);
}
.navigator div.subCategory .innerSub div.innerElementActive {
    width: 100%;
    padding: 12px 12px 16px;
    border-radius: 12px;
    background: var(--main);
    color: var(--select-sidebar-element);
    box-shadow: inset 1px 2px 4px var(--light-shadow-sidebar-selected);
    transition: var(--transition-theme);
}

ul.closeDropdown {
    opacity: 0;
    transition: all var(--transition-theme);
    overflow: hidden;
}
.closeDropdown.active {
    opacity: 1;
    transition: all var(--transition-theme);
    overflow: hidden;
    background-color: var(--bg-dark);
}

/* Footer Theme Sidebar */
.boxNav .footerSidebar {
    padding: 24px;
    border-top: 2px solid var(--light-bg-box);
    overflow: hidden;
}
.boxNav .footerSidebar .info {
    overflow: hidden;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: var(--secondary-text-color);
    height: 74px;
    width: 292px;
    position: relative;
    padding: 24px 12px;
}
.boxNav .footerSidebar .info .infoIcon {
    font-size: 20px;
    line-height: 29px;
}
.boxNav .footerSidebar .info .infoText {
    margin-left: 23px;
    position: absolute;
    top: 50%;
    left: 120px;
    width: 190px;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
}
.boxNav .footerSidebar .switchTheme {
    width: 100%;
    height: 40px;
    transition: all 0.4s;
    padding: 4px;
    background-color: var(--light-bg-box);
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.boxNav .footerSidebar .switchTheme .themeButton {
    width: calc(100% / 2 - 4px);
    border-radius: 32px;
    transition: all 0.4s;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: var(--secondary-text-color);
}
.boxNav .footerSidebar .switchTheme .themeButtonActive {
    width: calc(100% / 2 - 4px);
    border-radius: 32px;
    transition: all 0.4s;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: var(--light-text);
}
.selectedTheme {
    width: 112px;
    height: 32px;
    transition: all 0.8s;
    background-color: var(--light-gray-background);
    position: absolute;
    top: 4px;
    left: 4px;
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25),
        inset 0px -1px 1px rgba(0, 0, 0, 0.04),
        inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    border-radius: 32px;
    transition: all 0.5s ease-in-out;
}
.themeText {
    transition: all 1s;
}
.themeButton svg,
.themeButtonActive svg {
    margin-right: 8px;
    font-size: 27px;
}

/* Footer Theme Sidebar Collapse */
.sidebarCollapse .boxNav .footerSidebar .info .infoIcon {
    font-size: 20px;
    line-height: 29px;
    margin-left: 2px;
    transition: all 0.1s;
}
.sidebarCollapse .boxNav .footerSidebar .info .infoText {
    opacity: 0;
    transition: all 0.5s;
}
.sidebarCollapse .themeText {
    opacity: 0;
    transition: all 0.01s;
    position: absolute;
    z-index: -2;
}
.sidebarCollapse .selectedTheme {
    width: 32px;
    height: 32px;
    background-color: var(--light-gray-background);
    position: absolute;
    top: 4px;
    left: 4px;
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25),
        inset 0px -1px 1px rgba(0, 0, 0, 0.04),
        inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    border-radius: 32px;
    transition: all 0.5s ease-in-out;
}
.sidebarCollapse .boxNav .footerSidebar .switchTheme {
    width: 72px;
    transition: all 0.4s;
    height: 40px;
    padding: 4px;
    background-color: var(--light-bg-box);
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    transform: translateX(-13px);
}
.sidebarCollapse .boxNav .footerSidebar .switchTheme .themeButton {
    width: auto;
    border-radius: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: var(--secondary-text-color);
}
.sidebarCollapse .boxNav .footerSidebar .switchTheme .themeButtonActive {
    width: auto;
    border-radius: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: var(--light-text);
}
/* Theme selector */
.active {
    top: 4px;
    left: calc(100% / 2 - 8px);
}
.sidebarCollapse .active {
    top: 4px;
    left: calc(100% - 35px);
}
.sidebarCollapse .themeButton svg,
.sidebarCollapse .themeButtonActive svg {
    margin-right: 0px;
    font-size: 27px;
    margin-left: 2px;
}
.lightOn {
    color: #ecb22e;
}
.lightOff {
    color: #6f767e;
}
.darkOn {
    color: #cecfda;
}

.boxIcon {
    width: 38px;
    transition: all 0.8s;
    display: flex;
}
/* Query */
@media screen and (max-width: 991px) {
    .sidebar {
        width: 0;
    }
    .sidebarCollapse {
        width: 0;
    }
    .sidebarMobile {
        width: 310px;
        position: fixed;
        top: 0;
        z-index: 99;
        left: 0;
        transition: all 0.5s;
        background-color: var(--bg-dark);
        box-shadow: 2px 0px 9px var(--light-shadow);
    }
    .sidebarCollapseMobile {
        width: 310px;
        position: fixed;
        top: 0;
        left: -350px;
        z-index: 99;
        transition: all 0.5s;
        background-color: var(--bg-dark);
    }
}
