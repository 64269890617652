.swapBox {
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 30px;
    margin-block: 70px;
    color: var(--dark);
}
.title {
    font-weight: 600;
    margin-bottom: 30px;
}

.input,
.output {
    background-color: var(--bg-content);
    border-radius: var(--border-radius-standard);
    padding: 10px 20px 20px;
    position: relative;
}
.input .inputText {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
}
.input .balanceText {
    font-weight: 400;
    font-size: 15px;
}
.output .outputText {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
}

.coin {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--dark);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 90px;
}
.coin img {
    width: 32px;
}
.selectCoin {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.boxIcon {
    text-align: center;
    width: 110px;
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-standard);
    padding: 3px 7px;
    font-size: 13px;
    cursor: pointer;
}
.boxIcon:hover {
    background-color: var(--main-light);
    border-radius: var(--border-radius-standard);
    padding: 3px 7px;
    font-size: 13px;
}
.selectedBox {
    text-align: center;
    width: 110px;
    background-color: var(--main);
    border-radius: var(--border-radius-standard);
    padding: 3px 7px;
    font-size: 13px;
}
.check {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.check label {
    font-weight: 500;
    font-size: 11.9086px;
}
.inputField input,
.inputFieldOut input {
    font-size: 25px;
    caret-color: var(--dark);
    color: var(--dark);
    border-radius: var(--border-radius-standard);
}
.inputField input::placeholder,
.inputFieldOut input::placeholder {
    font-size: 25px;
}
.button {
    padding: 12px;
    font-weight: 400;
    font-size: 20px;
    margin-top: 30px;
    width: 100%;
    max-width: 130px;
}
.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: -10px;
    position: relative;
    z-index: 1;
}
.arrow .icon {
    background-color: var(--main);
    border-radius: 5px;
    padding: 5px 15px 1px;
    font-size: 20px;
}
.all {
    font-weight: 600;
    font-size: 13px;
    color: var(--main);
    cursor: pointer;
    background-color: var(--bg-dark);
    padding: 4px 8px;
    border-radius: var(--border-radius-min);
    margin-left: 15px;
}
.terms {
    color: var(--main);
    cursor: pointer;
}
.terms:hover,
.all:hover {
    color: var(--main-light);
}

.bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 576px) {
    .swapBox {
        padding: 30px 15px;
    }
    .bottom {
        flex-direction: column;
    }
    .button {
        width: 100%;
        max-width: 100%;
    }
    .input .balanceText {
        display: flex;
        flex-direction: column;
    }
    .coin {
        right: 8px;
        top: 15%;
    }
    .all {
        margin-left: 0;
        max-width: 100px;
        text-align: center;
        margin-top: 10px;
    }
}
