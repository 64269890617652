.button {
    background-color: rgba(217, 217, 217, 0.08);
    width: 120px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 0;
}
.button:hover {
    background-color: rgba(217, 217, 217, 0.2);
}

.container {
    position: relative;
}

.popupRedeem {
    padding: 30px 20px 10px;
    color: var(--dark);
    text-align: center;
}
.popupRedeem p {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
}
.popupRedeem .buttonPopupSingle {
    margin-block: 20px;
    font-weight: 400;
    font-size: 20px;
    padding: 12px;
    margin-top: 30px;
}
.popupRedeem .buttonPopup {
    margin-block: 20px;
    font-weight: 400;
    font-size: 20px;
    padding: 12px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popupRedeem .buttonsPopup {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
