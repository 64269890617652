.depositConfirmation {
    padding: 20px;
    color: var(--dark);
}
.iconsVerified {
    font-size: 100px;
    color: var(--success-secondary);
    text-align: center;
}
.message {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
}
