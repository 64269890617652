.currency {
    padding: 18px;
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-medium);
    height: 433px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-weight: 500;
    font-size: 13px;
    color: var(--dark);
    margin-bottom: 20px;
}

.innerChartText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.innerChartText h6 {
    color: var(--text-subtitle);
    font-weight: 500;
    font-size: 14px;
}

.innerChartText h5 {
    color: var(--dark);
    font-weight: 600;
    font-size: 16px;
    padding-block: 8px;
}

.labels {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.graphBox {
    display: flex;
    align-items: center;
    color: var(--dark);
    font-weight: 400;
    font-size: 12px;
}
.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
}
@media screen and (max-width: 1199px) {
    .currency {
        height: 433px;
    }
}
@media screen and (max-width: 404px) {
    .currency {
        height: 433px;
        padding-inline: 0;
    }
}
