.activities {
    height: 433px;
    padding: 18px;
    background-color: var(--bg-dark);
    border-radius: var(--border-radius-medium);
    padding-bottom: 40px;
}
.title {
    font-weight: 500;
    font-size: 16px;
    color: var(--dark);
    margin-bottom: 30px;
}

.singleBox {
    display: flex;
    gap: 15px;
    margin-top: 30px;
}
.singleBox:first-child {
    margin-top: 0;
}
.singleBox:not(:last-child) {
    border-bottom: 1px solid #334155;
}

.transaction {
    color: var(--dark);
    font-weight: 500;
    font-size: 12px;
}

.status {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 12px;
}

.status .label {
    color: var(--success-secondary);
}
.status .date {
    color: var(--text-subtitle);
}

.amount {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-subtitle);
    margin-bottom: 25px;
}
.group {
    max-height: 300px;
    overflow-y: scroll;
}
.noActivities {
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1199px) {
    .title {
        font-weight: 500;
        font-size: 18px;
        color: var(--dark);
        margin-bottom: 20px;
    }
    .singleBox {
        margin-top: 25px;
    }
    .transaction {
        font-size: 14px;
    }

    .status {
        font-size: 14px;
    }
    .amount {
        font-size: 14px;
    }
}
@media screen and (max-width: 991px) {
    .title,
    .amount {
        margin-bottom: 10px;
    }
}
