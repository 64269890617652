.buy {
    background-color: var(--bg-dark);
    padding: 30px 30px;
    margin-inline: auto;
    border-radius: var(--border-radius-standard);
    color: var(--dark);
}
.timer {
    margin-bottom: 20px;
    color: var(--warning);
    min-height: 25px;
    transition: all 0.5s ease;
    opacity: 0;
}
.timerDone {
    margin-bottom: 20px;
    color: var(--warning);
    min-height: 25px;
    transition: all 0.5s ease;
    opacity: 1;
}

.label {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-subtitle);
    margin-bottom: 10px;
}
.mandatory {
    color: var(--main);
}

.select {
    width: 100%;
    padding: 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    background-color: var(--bg-content);
    color: var(--dark);
}

.user,
.username {
    margin-bottom: 30px;
}
.deposit {
    margin-bottom: 45px;
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.toPay {
    font-weight: 700;
    font-size: 18px;
}
.toPay span {
    font-size: 24px;
    margin-left: 10px;
}

.button {
    width: 100%;
    padding: 15px 0;
    font-weight: 400;
    font-size: 18px;
    border-radius: var(--border-radius-medium);
}

.errorPopup {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
}
.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.buttons .button {
    width: 180px;
}

.amountXpl {
    color: var(--main-light);
    margin-bottom: 15px;
}
.amountXpl span {
    font-size: 21px;
}

@media screen and (max-width: 767px) {
    .bottom {
        flex-direction: column;
        row-gap: 20px;
    }
    .buttons {
        width: 100%;
        justify-content: center;
    }
    .buttons .button {
        width: 50%;
    }
    .fee {
        text-align: center;
    }
}
@media screen and (max-width: 576px) {
    .buy {
        padding: 30px 15px;
    }
    .toPay {
        text-align: center;
    }
    .buttons {
        flex-direction: column;
        width: 100%;
    }
    .buttons .button {
        width: 100%;
    }
}
