.progress {
    background-color: var(--bg-dark);
    padding: 40px 20px;
    margin: 0 auto;
    border-radius: var(--border-radius-standard);
    margin-top: 100px;
    text-align: center;
    color: var(--dark);
}
.progress h2 {
    font-weight: 800;
}
.retry {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
}
.retry span {
    color: var(--main);
    cursor: pointer;
}
.retry span:hover {
    color: var(--main-light);
}

.button {
    margin-top: 35px;
    max-width: 400px;
    margin-inline: auto;
    font-weight: 400;
    font-size: 19px;
    padding: 10px;
}
.subtitle {
    margin-top: 35px;
    max-width: 500px;
    margin-inline: auto;
}
.cta {
    display: block;
    margin-top: 50px;
    font-weight: 600;
    color: var(--text-subtitle);
}
